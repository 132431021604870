import FormDataUtils from '@/models/api/formDataHelper'
import {activityTypes} from "@/models/bulkEdit/activityTypes";

export default class BulkEditRequest {
  /**
   * The amount of bits to edit.
   * @type {string}
   */
  amountBits;

  /**
   * The ids of the classes to edit.
   * @type {Array<BulkEditClass>}
   */
  schoolClassIds;


  /**
   * The id of the mission/mediaquiz/extragame to edit.
   * @type {ExtraGame|Mission}
   */
  editItem;


  /**
   * The type of edit to perform. 0 = mission, 1 = extragame, 2 = MediaQuiz.
   * @type {int}
   */
  editType;


  constructor({amountBits, schoolClassIds, editItem, editType}) {
    this.amountBits = amountBits
    this.schoolClassIds = schoolClassIds
    this.editItem = editItem
    this.editType = editType
  }

  /**
   * convert to form data.
   */
  toFormData() {
    const formData = new FormData()
    formData.append('amountBits', this.amountBits)
    FormDataUtils.addList(formData, this.schoolClassIds.map(e => e.id), 'schoolClassIds')
    formData.append('editType', (this.editType - 1).toString())
    if (this.editItem != null) {
      formData.append('editItemId', this.editItem.id.toString())
    }
    return formData
  }


  get getSuccessString() {


    let type = activityTypes.find((type) => type.id === this.editType)


    try {
      return 'Wijziging succesvol voor de volgende klassen: ' + this.schoolClassIds.map((schoolClass) => schoolClass.schoolClassName).join(', ')
      + '\nBit hoeveelheid is veranderd naar: ' + this.amountBits + '\nvoor de volgende types: '
      + type.name + '\nvoor de volgende items: ' + (type.id === 3 ? 'Mediaquiz' : (this.editItem?.name ?? 'Mediaquiz'))
    } catch (e) {
      console.error("unable to get success string", e);
    }
    return 'Success'
  }

}
