<template>
  <div class="mission-days-wrapper">
    <p class="text">{{ $t('BULK_EDIT.SELECT_DAY') }}</p>

    <div class="mission-day-buttons">
      <div
        v-for="missionDay in 5"
        :key="missionDay"
        class="day-button"
        :class="{'selected': selectedDay === missionDay}"
        @click="selectDay(missionDay)">


        <i18n-t keypath="BULK_EDIT.DAY_TEXT" tag="div">
          <template #day>
            <span> {{ missionDay }} </span>
          </template>
        </i18n-t >

        <img v-if="selectedDay === missionDay" class="remove-icon" src="~@/assets/icons/icn_remove.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionDaySelecter',
  props: {
    selectedDay: {
      type: Number,
      default: null
    }
  },
  emits: ['day-selected'],
  setup(props, { emit }) {
    function selectDay(day) {
      emit('day-selected', day)
    }

    return {
      selectDay
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.mission-days-wrapper {
  margin-bottom: rem(24);

  .text {
    margin-bottom: rem(8);
  }
}

.mission-day-buttons {
  display: flex;
  gap: rem(25);
}

.day-button {
  height: rem(46);
  width: rem(165);
  border-radius: rem(8);
  background-color: #FFFFFF;
  box-shadow: rem(2) rem(2) 0 0 rgba(0,0,0,0.1);
  color: var(--blue_light_01);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(16);
  cursor: pointer;

  &.selected {
    background-color: #0E4E95;
    color: #FFFFFF;
  }
}
</style>