export default class FormDataUtils{


  /**
   * Add a list to the form data.
   * @param formData {FormData} the form data to add the list to.
   * @param list {any[]} the list to add.
   * @param name {string} the name of the list.
   * @return {formData} the form
   */
  static addList(formData, list, name,numbered = false) {
    list.forEach((item,index) => {
      formData.append(`${name}[${numbered ? index : ''}]`, item.toString());
    });
    return formData
  }

}
