<template>
  <div class="class-selecter-wrapper">
    <p class="text">School</p>

    <div class="search-container">
      <Searchbar
        ref="searchComponent"
        :text="searchProps"

        :search-input="searchFunction"/>

      <div v-if="searchTerm.length > 0" class="search-results-container">
        <!-- TODO: Switch to search results from API -->
        <table class="results-table">
          <tbody>
            <tr
              v-for="classObj in searchResults"
              :key="classObj.id"
              class="search-result"
              @click="selectClass(classObj)">

              <td class="class-name">{{ classObj.schoolClassName }}</td>
              <td class="school-name">{{ classObj.schoolName }}</td>
              <td class="school-postcode">{{ classObj.schoolPostalCode }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="selected-classes-container">
      <div
        v-for="classObj in selectedClasses"
        :key="classObj.id"
        class="selected-class">

        <div class="bits-container">
          {{ classObj.bitsEarned }}
        </div>

        <p class="class-name">{{ classObj.schoolClassName }}</p>
        <p class="school-name">{{ classObj.schoolName }}</p>
        <p class="school-zipcode">{{ classObj.schoolPostalCode }}</p>

        <img class="remove-icon" src="~@/assets/icons/icn_remove.svg" alt="" @click="unselectedClass(classObj)">
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Searchbar from '@/components/partials/search/Searchbar.vue'
import { computed } from '@vue/reactivity'
import { UPDATE_BULK_EDIT_SEARCH } from '@/store/modules/helpdesk/bulkEdit/actions'
import { useStore } from 'vuex'

export default {
  name: 'ClassSelector',
  components: { Searchbar },
  emits: ['select-class','unselect-class'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const store = useStore()

    const searchProps = {
      placeholder: t('BULK_EDIT.SEARCH_PLACEHOLDER'),
      searchId: 'schoolsSearch'
    }

    const searchComponent = ref(null)
    const searchTerm = ref('')


    function searchFunction(search) {
      searchTerm.value = search
      console.log('searching', search)
      store.dispatch(UPDATE_BULK_EDIT_SEARCH, search)
    }



    const selectedClasses = computed(() => store.getters.getSelectedClasses)
    const searchResults = computed(() => store.getters.getFoundClasses)


    function selectClass(classObj) {
      emit('select-class', classObj)
    }

    function unselectedClass(classObj) {
      emit('unselect-class', classObj)
    }


    return {
      searchComponent,
      searchProps,
      searchTerm,
      searchFunction,
      selectClass,
      selectedClasses,
      searchResults,
      unselectedClass
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.class-selecter-wrapper {
  margin-bottom: rem(24);

  .text {
    margin-bottom: rem(8);
  }
}

:deep .search-input {
  width: rem(600);
}

.search-container {
  position: relative;
}

.search-results-container {
  z-index: 100;
  @include position(absolute, $top: rem(56));
  width: rem(600);
  border-radius: rem(8);
  background-color: #FFFFFF;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_light_01);
  padding: rem(16) 0;
}

.results-table {
  border-collapse: collapse;
  width: 100%;
}

.search-result:hover {
  font-weight: bold;
}

.search-result {
  cursor: pointer;

  &:nth-child(even) {
    background-color: #F4F4F4;
  }


  .class-name {
    width: 50%;
    padding: rem(8) 0 rem(8) rem(35);
  }

  .school-name {
    padding: rem(8) 0;
  }

  .school-postcode {
    text-align: right;
    padding: rem(8) rem(35) rem(8) 0;
  }
}

.selected-classes-container {
  display: flex;
  gap: rem(25);
  flex-wrap: wrap;
  margin-top: rem(16);
}

.selected-class {
  height: rem(46);
  width: rem(650);
  padding: rem(4) rem(16);
  background-color: #0E4E95;
  color: #FFFFFF;
  border-radius: rem(8);
  display: flex;
  align-items: center;
  gap: rem(14);

  .bits-container {
    height: 100%;
    width: 13%;
    background-color: #FFFFFF;
    color: #0E4E95;
    border-radius: rem(8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .class-name {
    width: 35%;
  }

  .school-name {
    width: 32%
  }

  .school-zipcode {
    width: 13%
  }

  .remove-icon {
    margin: auto;
    cursor: pointer;
  }

}
</style>
