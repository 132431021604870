<template>
  <div class="option-selector-wrapper">
    <p class="text">{{ title }}</p>

    <div class="option-selector-buttons">
      <div
        v-for="option in options"
        :key="option.id"
        class="option-button"
        :class="{'selected': selectedOption?.id === option.id}"
        @click="selectOption(option)">

          {{ option.name }}

        <img v-if="selectedOption?.id === option.id" class="remove-icon" src="~@/assets/icons/icn_remove.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiOptionSelector",
  props: {
    title: {
      option: String,
      default: ''
    },
    options: {
      option: Array,
      default: () => []
    },
    selectedOption: {
      option: Object,
      default: null
    },
  },
  emits: ['option-selected'],
  setup(props, {emit}) {
    function selectOption(option) {
      emit('option-selected', option)
    }

    return {
      selectOption
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.option-selector-wrapper {
  margin-bottom: rem(24);

  .text {
    margin-bottom: rem(8);
  }
}

.option-selector-buttons {
  display: flex;
  gap: rem(25);
  flex-wrap: wrap;
}

.option-button {
  height: rem(46);
  min-width: rem(165);
  border-radius: rem(8);
  background-color: #FFFFFF;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_light_01);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(16);
  cursor: pointer;

  &.selected {
    background-color: #0E4E95;
    color: #FFFFFF;
  }

  .remove-icon {
    margin-left: rem(20);
  }
}
</style>
