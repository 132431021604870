<template>
  <div class="content-container bulk-edit-wrapper">
    <PageTitle :title="$t('BULK_EDIT.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('BULK_EDIT.SUBTITLE') }}</h1>


    <MultiOptionSelector
      :title="$t('BULK_EDIT.TYPE.MISSION')"
      :options="activityTypes"
      :selected-option="selectedType"
      :translate-name="true"
      @option-selected="setType"/>

    <MultiOptionSelector
      v-if="selectedType && selectedType.id === 1"
      :title="$t('BULK_EDIT.TYPE.MISSION')"
      :options="bulkEditMissions"
      :selected-option="selectedItemId"
      @option-selected="setSelectedItemId"/>

    <MultiOptionSelector
      v-if="selectedType && selectedType.id === 2"
      :title="$t('BULK_EDIT.TYPE.EXTRA_GAMES')"
      :options="bulkEditExtraGames"
      :selected-option="selectedItemId"
      @option-selected="setSelectedItemId"/>



    <ClassSelector
      v-if="selectedType?.id === 3 || selectedItemId != null"
      @select-class="selectClass"
      @unselect-class="deselectClass"
    />

    <FormWrapper v-if="selectedClasses" :send-form-data="validateData">
      <template #form-content>
        <div class="bits-form">
          <InputField
            :field-title="'Wijzig bits'"
            :field-name="'bits'"
            :rules="'required'"
            :type="'number'"
            :placeholder="'Voer het aantal in'"
          />

          <button type="submit" class="pink-button submit-button">
            Accepteren
          </button>
        </div>
      </template>
    </FormWrapper>


    <div class="edit-result" v-if="bulkEditingStatus">{{bulkEditingStatus}}</div>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle.vue'
import MissionDaySelecter from '@/components/partials/bulkEdit/MissionDaySelecter.vue'
import ClassSelector from '@/components/partials/bulkEdit/ClassSelector.vue'
import FormWrapper from '@/components/yo-form/FormWrapper.vue'
import InputField from '@/components/yo-form/InputField.vue'
import { useStore } from 'vuex'
import {
  ADD_SELECTED_CLASS, MAKE_BULK_EDIT,
  SET_SELECTED_ITEM_ID,
  SET_SELECTED_TYPE,
  TOGGLE_SELECTED_CLASS
} from '@/store/modules/helpdesk/bulkEdit/actions'
import {computed} from "@vue/reactivity";
import MultiOptionSelector from "@/components/partials/bulkEdit/MutliOptionSelector";
import { useI18n } from 'vue-i18n'
import {SET_SELECTED_CLASS} from "@/store/modules/teacherPortal/mutations";
import BulkEditRequest from "@/models/api/requests/bulkEditRequest";
import {activityTypes} from "@/models/bulkEdit/activityTypes";

export default {
  name: 'BulkEdit',
  components: {MultiOptionSelector, PageTitle, ClassSelector, FormWrapper, InputField },
  setup(props) {
    const bulkEditStore = useStore()



    const selectedType = computed(() => bulkEditStore.getters.getSelectedType)
    const selectedItemId = computed(() => bulkEditStore.getters.getSelectedItemId)
    const foundClasses = computed(() => bulkEditStore.getters.getFoundClasses)
    const selectedClasses = computed(() => bulkEditStore.getters.getSelectedClasses)
    const bulkEditMissions = computed(() => bulkEditStore.getters.getBulkEditMissions)
    const bulkEditExtraGames = computed(() => bulkEditStore.getters.getBulkEditExtraGames)
    const bulkEditingStatus = computed(() => bulkEditStore.getters.getEditingStatus)

    function setType(type) {
      bulkEditStore.dispatch(SET_SELECTED_TYPE, type)
    }

    function setSelectedItemId(day) {
      bulkEditStore.dispatch(SET_SELECTED_ITEM_ID, day)
    }

    function selectClass(selectedClass) {
      bulkEditStore.dispatch(ADD_SELECTED_CLASS,selectedClass)
    }

    function deselectClass(selectedClass) {
      console.log('deselectClass', selectedClass);
      bulkEditStore.dispatch(TOGGLE_SELECTED_CLASS,selectedClass)
    }

    function validateData(data) {

      const bulkEditRequest = new BulkEditRequest({
        editType: selectedType.value.id,
        editItem: selectedItemId.value ?? null,
        schoolClassIds: selectedClasses.value,
        amountBits: data.bits
      })

      bulkEditStore.dispatch(MAKE_BULK_EDIT, bulkEditRequest);
    }


    return {
      setType,
      setSelectedItemId,
      selectedType,
      selectedItemId,
      selectClass,
      selectedClasses,
      foundClasses,
      validateData,
      activityTypes,
      bulkEditExtraGames,
      deselectClass,
      bulkEditMissions,
      bulkEditingStatus
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.bits-form {
  width: rem(625);
  display: flex;
  gap: rem(25);
  align-items: flex-end;

  .submit-button {
    height: rem(46);
  }

  :deep .form-group {
    margin-bottom: 0;
  }
}

.edit-result{
  white-space: pre;
  margin-top: rem(20);
  font-weight: bold;
  font-size: rem(15);
}
</style>
